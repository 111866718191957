import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useMMAuth } from '@machinemetrics/mm-react-tools';

export const useUpdateActivitySet = () => {
  const { request, urls } = useMMAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activitySet, setActivitySet] = useState(null);

  const fetchAndUpdateActivitySet = async (activitySetRef, newWorkorderId) => {
    setLoading(true);
    try {
      // Fetch the current activitySet
      const currentActivitySet = await getActivitySet(
        request,
        urls,
        activitySetRef
      );

      // Update the workorderId with the new value
      currentActivitySet.workOrderId = newWorkorderId;

      // Update the activitySet using the API
      const updatedActivitySet = await updateActivitySet(
        request,
        urls,
        currentActivitySet
      );

      // Set the updated activitySet in state
      setActivitySet(updatedActivitySet);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    activitySet,
    loading,
    error,
    fetchAndUpdateActivitySet,
  };
};

export const useSplitActivitySet = () => {
  const { request, urls } = useMMAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newActivitySet, setNewActivitySet] = useState(null);

  const splitActivitySet = async (activity, splitTime = null) => {
    setLoading(true);
    try {
      // Fetch the current activitySet using the activity's reference
      const activitySet = await getActivitySet(
        request,
        urls,
        activity.activitySet.activitySetRef
      );

      let activityIndex = activitySet.activities.findIndex(
        (a) => activity.activityRef === a.activityRef
      );

      //identify all activities at the current activity and later, and prepare them to be moved into a new set
      let activitiesToMove = activitySet.activities.slice(
        activityIndex,
        activitySet.activities.length
      );
      const newActivities = activitiesToMove.map((activity) => {
        return {
          activityTypeRef: activity.activityTypeRef,
          startAt: activity.startAt,
          endAt: activity.endAt,
        };
      });

      //for the first activity, set the start date to the split time
      if (splitTime) newActivities[0].startAt = splitTime.toUTC().toISO();

      // Create a new ActivitySet with the same properties
      const newSet = {
        actualParts: activitySet.actualParts,
        expectedSetupDuration: activitySet.expectedSetupDuration,
        expectedUnitDuration: activitySet.expectedUnitDuration,
        idealUnitDuration: activitySet.idealUnitDuration,
        jobId: activitySet.jobId,
        machineRef: activitySet.machineRef,
        partCountMultiplier: activitySet.partCountMultiplier,
        workOrderId: activitySet.workOrderId,
        activities: newActivities,
      };
      //close out the new activitySet as long as the last activity is not a null endDate
      if (newSet.activities[newSet.activities.length - 1].endAt !== null) {
        newSet.closedAt = activitySet.closedAt;
      }

      //close out activityset if it's open and there is no current activity with an end of null
      if (!activitySet.closedAt) {
        activitySet.closedAt = DateTime.now().toUTC().toISO();
      }

      //delete all activities that are to be re-created in the new set
      const modifiedActivities = [];
      const originalActivities = activitySet.activities;
      for (let i = activityIndex; i < activitySet.activities.length; i++) {
        if (splitTime && i === activityIndex) {
          activitySet.activities[i].endAt = splitTime.toUTC().toISO();
        } else {
          activitySet.activities[i].delete = true;
        }
        modifiedActivities.push(activitySet.activities[i]);
      }
      activitySet.activities = modifiedActivities;
      await updateActivitySet(request, urls, activitySet);
      //remove the deleted activities from the activitySet
      activitySet.activities = originalActivities.splice(activityIndex);

      try {
        //create a new set that contains the remaining activities
        const newActivitySet = await createActivitySet(newSet);
        // Set the new activitySet in state
        setNewActivitySet(newActivitySet);
      } catch (e) {
        //there is no way to revert a closed activitySet, but we can add the activities back
        modifiedActivities.forEach((a) => (a.delete = false));
        activitySet.activities = modifiedActivities;
        await updateActivitySet(request, urls, activitySet);
        throw e;
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    newActivitySet,
    loading,
    error,
    splitActivitySet,
  };
};

const updateActivitySet = async (request, urls, activitySet) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(activitySet),
  };
  try {
    const result = await request(
      `${urls.apiUrl}/activities/activity-sets/${activitySet.activitySetRef}`,
      options
    );
    return result.activitySet;
  } catch (err) {
    let msg =
      err &&
      err.json &&
      err.json.errors &&
      err.json.errors.length > 0 &&
      err.json.errors[0];
    if (!msg) msg = err && err.json;
    throw err;
  }
};

export const getActivitySet = async (request, urls, activitySetRef) => {
  const options = {
    method: 'GET',
  };
  try {
    const response = await request(
      `${urls.apiUrl}/activities/activity-sets/${activitySetRef}`,
      options
    );
    return response.activitySet;
  } catch (err) {
    let msg =
      err &&
      err.json &&
      err.json.errors &&
      err.json.errors.length > 0 &&
      err.json.errors[0];
    if (!msg) msg = err && err.json;
    throw err;
  }
};

export const getOperation = async (request, urls, operationId) => {
  const options = {
    method: 'GET',
  };
  try {
    const response = await request(
      `${urls.apiUrl}/jobs/${operationId}`,
      options
    );
    return response;
  } catch (err) {
    let msg =
      err &&
      err.response &&
      err.response.data &&
      err.response.data.errors &&
      err.response.data.errors.length > 0 &&
      err.response.data.errors[0];
    if (!msg) msg = err && err.response && err.response.data;
    throw err;
  }
};

const createActivitySet = async (request, urls, activitySet) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(activitySet),
  };
  try {
    const result = await request(
      `${urls.apiUrl}/activities/activity-sets`,
      options
    );
    return result.activitySet;
  } catch (err) {
    let msg =
      err &&
      err.json &&
      err.json.errors &&
      err.json.errors.length > 0 &&
      err.json.errors[0];
    if (!msg) msg = err && err.json;
    throw err;
  }
};

export const productionQuery = async (request, urls, query) => {
  try {
    const data = await request(`${urls.apiUrl}/reports/production`, {
      method: 'POST',
      body: JSON.stringify(query),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return { data, error: null, loading: false };
  } catch (error) {
    return { data: null, error, loading: false };
  }
};

// Extracted common fetching logic into a separate async function
export const fetchActivityData = async (request, urls, activity, machineId) => {
  try {
    const query = {
      start: activity.startAt,
      end: activity.endAt ? activity.endAt : new Date().toISOString(),
      exactRange: true,
      data: [
        { metric: 'goodParts' },
        { metric: 'rejectedParts' },
        {
          metric:
            activity.activityType.name === 'Setup'
              ? 'setupTime'
              : 'productionTime',
        },
        { metric: 'timeInCycle' },
      ],
      filter: {
        machine: [machineId],
      },
    };

    const data = await productionQuery(request, urls, query);
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching single activity data:', error);
    return { data: null, error };
  }
};

export const useFetchActivityData = (activity, machineId) => {
  const { request, urls } = useMMAuth();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchActivityData(request, urls, activity, machineId).then((result) => {
      setData(result.data);
      setError(result.error);
    });
  }, [activity, machineId, request, urls]);

  return { data, error };
};

export const useFetchMultipleActivityData = (activities, machineId) => {
  const { request, urls } = useMMAuth();
  const [activitiesData, setActivitiesData] = useState({});
  const [loadingActivities, setLoadingActivities] = useState(true);

  useEffect(() => {
    // Asynchronous function to fetch data for a single activity
    const fetchDataForActivity = async (activity) => {
      const { data, error } = await fetchActivityData(
        request,
        urls,
        activity,
        machineId
      );
      if (data !== null || error !== null) {
        return { [activity.activityRef]: { data, error } };
      }
      return null;
    };

    // Asynchronous function to fetch data for all activities
    const fetchAllActivities = async () => {
      // Start all fetch operations concurrently
      const allData = await Promise.all(
        activities.map((activity) => fetchDataForActivity(activity))
      );

      // Combine all results into a single object
      const combinedData = allData.reduce((acc, cur) => {
        if (cur) {
          return { ...acc, ...cur };
        }
        return acc;
      }, {});

      // Update the state with the combined data
      setActivitiesData(combinedData);
      setLoadingActivities(false);
    };

    // Kick off the fetching process
    fetchAllActivities();
  }, [activities, machineId, request, urls]);

  return { activitiesData, loadingActivities };
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};
