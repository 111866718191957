import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useLaborTickets } from '../utils/hooks';
import NewLaborTicket from './NewLaborTicket';
import styled from 'styled-components';
import Palette from './Palette';
import {
  Box,
  Heading,
  Text,
  Button,
  Divider,
  Flex,
  Spacer,
} from '@chakra-ui/react';

const LaborItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 4px;
  background-color: #f5f5f5;
  flex: 1;
`;

const LaborHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LaborHeading = styled.h3`
  ${LaborHeader}
  font-size: 1.5em;
`;

const LaborDetail = styled.div`
  margin-bottom: 4px;
`;

const LaborPill = styled.span`
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.75em;
  display: inline-block;
  align-self: center;
  color: black;
  background-color: ${(props) =>
    props.transactionId ? Palette.Green400 : Palette.Yellow500};
`;

const LaborRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  border: 1px solid #ccc;
  background-color: ${(props) =>
    props.type === 'RUN' ? Palette.Green100 : Palette.Yellow100};
`;

const LaborItems = ({
  activity,
  activitySet,
  activityData,
  timezone,
  employees,
  workOrderOperation,
}) => {
  const { laborTickets, refetch } = useLaborTickets(activitySet, activity);
  const [newTickets, setNewTickets] = useState([]);
  const handleNewTicket = () => {
    const newTicket = { id: Date.now() }; // Using timestamp as a unique ID
    setNewTickets((prevTickets) => [...prevTickets, newTicket]);
  };
  const handleRemoveTicket = (id) => {
    setNewTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.id !== id)
    );
  };

  const onInsert = () => {
    refetch();
  };

  return (
    <Box>
      {laborTickets.map((ticket) => (
        <>
          <LaborHeader>
            <LaborHeading>Existing Labor Ticket</LaborHeading>
            {!ticket.laborTicketId && (
              <LaborPill type={ticket.laborTicketId}>Pending</LaborPill>
            )}
          </LaborHeader>
          <div key={ticket.transactionId} className="labor-item">
            <LaborRow type={ticket.type}>
              <LaborDetail>Transaction Id: {ticket.laborTicketId}</LaborDetail>
              <LaborDetail>
                {DateTime.fromISO(ticket.clockIn)
                  .setZone(timezone)
                  .toLocaleString(DateTime.DATETIME_SHORT)}{' '}
                -{' '}
                {DateTime.fromISO(ticket.clockOut)
                  .setZone('America/New_York')
                  .toLocaleString(DateTime.DATETIME_SHORT)}
              </LaborDetail>

              <LaborDetail>Good Parts: {ticket.goodParts}</LaborDetail>
              <LaborDetail>Bad Parts: {ticket.badParts}</LaborDetail>
              <LaborDetail>Employee: {ticket.personId}</LaborDetail>
              <LaborDetail>
                Labor Time:{' '}
                {DateTime.fromISO(ticket.clockOut)
                  .diff(DateTime.fromISO(ticket.clockIn), 'hours')
                  .hours.toFixed(2)}{' '}
                hours
              </LaborDetail>
            </LaborRow>
          </div>
        </>
      ))}
      {newTickets.map((ticket, index) => (
        <NewLaborTicket
          activity={activity}
          activitySet={activitySet}
          activityData={activityData}
          timezone={timezone}
          employees={employees}
          workOrderOperation={workOrderOperation}
          onInsert={onInsert}
          onRemove={() => handleRemoveTicket(ticket.id)}
          id={ticket.id}
        />
      ))}
      <Button size="xs" m="1" colorScheme="blue" onClick={handleNewTicket}>
        Add New Labor Ticket
      </Button>
    </Box>
  );
};

export default LaborItems;
