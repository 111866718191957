import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useAddNewLaborTicket } from '../utils/hooks';
import styled from 'styled-components';
import Palette from './Palette';
import {
  Box,
  Heading,
  Text,
  Button,
  Divider,
  Flex,
  Spacer,
  Input,
  Select,
  Grid,
  GridItem,
} from '@chakra-ui/react';

const LaborItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 4px;
  background-color: #f5f5f5;
  flex: 1;
`;

const LaborHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LaborHeading = styled.h3`
  ${LaborHeader}
  font-size: 1.5em;
`;

const LaborDetail = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & label {
    margin-right: 10px;
    width: 100px;
  }

  & input {
    flex-grow: 1;
  }

  & select {
    flex-grow: 1;
  }
`;

const LaborRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  border: 1px solid #ccc;
  background-color: ${(props) =>
    props.type === 'Production' ? Palette.Green100 : Palette.Yellow100};
`;

const isoToDateTimeLocal = (isoString, timezone) => {
  if (!isoString) return '';
  const dt = DateTime.fromISO(isoString, { zone: timezone });
  return dt.isValid ? dt.toFormat("yyyy-MM-dd'T'HH:mm") : '';
};

const initializeFormData = (activity, activityData, timezone) => ({
  clockIn: isoToDateTimeLocal(activity?.startAt, timezone),
  clockOut: isoToDateTimeLocal(
    activity?.endAt ?? DateTime.now().toISO(),
    timezone
  ),
  goodQty: activityData?.goodParts ?? 0,
  badQty: activityData?.rejectedParts ?? 0,
  employeeId: '',
});

const NewLaborTicket = ({
  activity,
  activitySet,
  activityData,
  timezone,
  employees,
  workOrderOperation,
  onInsert,
  onRemove,
  id,
  ...props // Just in case you have other props in the future
}) => {
  const [formData, setFormData] = useState(() =>
    initializeFormData(activity, activityData, timezone)
  );
  const { data, error, addNewLaborTicket } = useAddNewLaborTicket();

  useEffect(() => {
    if (!error && data) {
      if (typeof onInsert === 'function') {
        onInsert(); // Notify parent component of successful submission
        if (typeof onRemove === 'function') {
          onRemove(id); // Remove the component from the list
        }
      }
    }
  }, [data, error, onInsert, onRemove, id]);

  useEffect(() => {
    if (activity) {
      setFormData(initializeFormData(activity, activityData, timezone));
    }
  }, [activity, activityData, timezone]);

  const handleSubmit = async () => {
    const ticket = {
      clockIn: DateTime.fromFormat(
        formData.clockIn,
        "yyyy-MM-dd'T'HH:mm"
      ).toISO(),
      clockOut: DateTime.fromFormat(
        formData.clockOut,
        "yyyy-MM-dd'T'HH:mm"
      ).toISO(),
      goodQty: formData.goodQty,
      badQty: formData.badQty,
      type: activity.activityType.name === 'Production' ? 'RUN' : 'SETUP',
      transactionDate: DateTime.now().toISO(),
      workOrderId: workOrderOperation.workOrderId,
      operationSequence: parseInt(workOrderOperation.sequenceNumber),
      workOrderLot: workOrderOperation.lot,
      workOrderSplit: workOrderOperation.split,
      workOrderSub: workOrderOperation.sub,
      resourceId: workOrderOperation.resourceId,
      employeeId: formData.employeeId,
    };
    addNewLaborTicket(ticket);
  };

  return (
    <Box m="2">
      <Flex alignItems="center" justifyContent="space-between">
        <LaborHeading>New Labor Ticket</LaborHeading>
        {error && <span>Error Submitting Labor Ticket</span>}{' '}
        {/* display "Error" when an error occurs */}
        <Button
          margin="1"
          size="xs"
          colorScheme="red"
          onClick={() => onRemove(id)}
        >
          Remove
        </Button>
      </Flex>
      <Flex
        p="2"
        flexDirection="column"
        bg={
          activity?.activityType?.name === 'Production'
            ? Palette.Green100
            : Palette.Yellow100
        }
        border="1px"
        borderColor="gray.300"
      >
        <Flex>
          <Text minW="100">Clock In:</Text>
          <Input
            size="sm"
            id="clockIn"
            type="datetime-local"
            value={formData.clockIn}
            onChange={(e) =>
              setFormData({ ...formData, clockIn: e.target.value })
            }
          />
        </Flex>
        <Flex>
          <Text minW="100">Clock Out:</Text>
          <Input
            size="sm"
            id="clockOut"
            type="datetime-local"
            value={formData.clockOut}
            onChange={(e) =>
              setFormData({ ...formData, clockOut: e.target.value })
            }
          />
        </Flex>
        <Flex>
          <Text minW="100">Good Parts:</Text>
          <Input
            size="sm"
            id="goodQty"
            type="number"
            value={formData.goodQty}
            onChange={(e) =>
              setFormData({
                ...formData,
                goodQty: parseInt(e.target.value, 10),
              })
            }
          />
        </Flex>
        <Flex>
          <Text minW="100">Bad Parts:</Text>
          <Input
            size="sm"
            id="badQty"
            type="number"
            value={formData.badQty}
            onChange={(e) =>
              setFormData({ ...formData, badQty: parseInt(e.target.value, 10) })
            }
          />
        </Flex>
        <Flex>
          <Text minW="100">Employee:</Text>
          <Select
            size="sm"
            id="employeeId"
            value={formData.employeeId}
            onChange={(e) =>
              setFormData({ ...formData, employeeId: e.target.value })
            }
          >
            <option value="" disabled>
              Select an employee
            </option>{' '}
            {/* Default option */}
            {employees &&
              employees.map((employee, index) => (
                <option
                  key={employee.personId}
                  value={employee.personId ?? index}
                >{`${employee.personId}`}</option>
              ))}
          </Select>
        </Flex>
        <Flex justifyContent="right">
          <Button
            colorScheme="blue"
            m="1"
            size="xs"
            onClick={handleSubmit}
            title="Click to create this labor ticket"
          >
            Save Labor Ticket
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NewLaborTicket;
