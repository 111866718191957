import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSplitActivitySet } from '../utils/mmutils';
import SplitModal from './SplitModal';
import ExecutionBar from './ExecutionBar';
import Palette from './Palette';
import styled from 'styled-components';
import {
  Box,
  Heading,
  Text,
  Button,
  Divider,
  Flex,
  Spacer,
} from '@chakra-ui/react';

const ExecutionBarContainer = styled.div`
  width: 100%; /* Take up all available width */
  height: 10px; /* Fixed height */
`;

const ActivityItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 4px;
  background-color: #f5f5f5;
  flex: 1;
`;

const ActivityHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 4px;
`;

const ActivityHeading = styled.h3`
  ${ActivityHeader}
  font-size: 1.5em;
`;

const ActivityDetail = styled.div`
  margin-bottom: 4px;
`;

const SplitButton = styled.button`
  padding: 2px 5px;
  font-size: 0.6em;
  text-align: center;
  cursor: pointer;
`;

const ActivityRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  border: 1px solid #ccc;
  background-color: ${(props) =>
    props.type === 'Production' ? Palette.Green100 : Palette.Yellow100};
`;

const ActivityItem = ({ activityData, activity, timezone }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const isMounted = useRef(true);
  const { splitActivitySet } = useSplitActivitySet();

  const endValue = useMemo(() => {
    return activity.endAt ? activity.endAt : DateTime.now().toISO();
  }, [activity.endAt]);

  const handleModalConfirm = (action, time) => {
    if (action === 'split') {
      splitActivitySet(activity)
        .then(() => {
          console.log('ActivitySet split successfully!');
        })
        .catch((err) => {
          console.error('Failed to split ActivitySet:', err);
        });
    } else if (action === 'time') {
      splitActivitySet(
        activity,
        DateTime.fromISO(time).setZone('utc').setZone(timezone)
      )
        .then(() => {
          console.log('ActivitySet split at time successfully!');
        })
        .catch((err) => {
          console.error('Failed to split at time: ', err);
        });
    }
    setShowModal(false);
  };

  const handleSplitClick = () => {
    setShowModal(true); // Show the modal when the split button is clicked
  };

  useEffect(() => {
    if (isMounted.current && !isDataLoaded) {
      setIsDataLoaded(true);
    }

    return () => {
      isMounted.current = false;
    };
  }, [isDataLoaded]);

  const [firstActive, setFirstActive] = useState();
  const [lastActive, setLastActive] = useState();
  const activeCallback = (firstActive, lastActive) => {
    setFirstActive(firstActive?.toLocaleString(DateTime.TIME_SIMPLE));
    setLastActive(lastActive?.toLocaleString(DateTime.TIME_SIMPLE));
  };

  const productionHours =
    activityData && activityData.productionTime
      ? (activityData.productionTime / 3600000).toFixed(2)
      : 0;
  const setupHours =
    activityData && activityData.setupTime
      ? (activityData.setupTime / 3600000).toFixed(2)
      : 0;
  const activeHours =
    activityData && activityData.timeInCycle
      ? (activityData.timeInCycle / 3600000).toFixed(2)
      : 0;

  return (
    <ActivityItemContainer>
      <ActivityHeader>
        <ActivityHeading>MachineMetrics Activity</ActivityHeading>
      </ActivityHeader>
      <ActivityRow type={activity.activityType.name}>
        <ActivityDetail>
          {DateTime.fromISO(activity.startAt)
            .setZone(timezone)
            .toLocaleString(DateTime.DATETIME_SHORT)}{' '}
          -{' '}
          {activity.endAt
            ? DateTime.fromISO(activity.endAt)
                .setZone(timezone)
                .toLocaleString(DateTime.DATETIME_SHORT)
            : 'In Progress'}
        </ActivityDetail>
        <ActivityDetail>
          Good Parts: {activityData?.goodParts ? activityData.goodParts : 0}
        </ActivityDetail>
        <ActivityDetail>
          Rejected Parts:{' '}
          {activityData?.rejectedParts ? activityData.rejectedParts : 0}
        </ActivityDetail>
        <ActivityDetail>
          {activity.activityType.name === 'Setup'
            ? `Setup Time: ${setupHours} hours`
            : `Production Time: ${productionHours} hours`}
        </ActivityDetail>
        <ActivityDetail>Active Time: {activeHours} hours</ActivityDetail>
        <ActivityDetail>
          First Active: {firstActive}, Last Active: {lastActive}
        </ActivityDetail>
        <ExecutionBarContainer>
          <ExecutionBar
            machineRef={activity.activitySet.machine.machineRef}
            start={activity.startAt}
            end={endValue}
            height={15}
            activeCallback={activeCallback}
          ></ExecutionBar>
        </ExecutionBarContainer>
      </ActivityRow>
      {showModal && (
        <SplitModal
          activity={activity}
          onClose={() => setShowModal(false)}
          onConfirm={handleModalConfirm}
        />
      )}
    </ActivityItemContainer>
  );
};

export default ActivityItem;
