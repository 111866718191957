import { useEffect, useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useMMAuth } from '@machinemetrics/mm-react-tools';

export const useAsyncEffect = (delegate, stateTriggers, destroy) => {
  useEffect(() => {
    const controller = new AbortController();

    async function asyncEffect() {
      await delegate(controller.signal);
    }

    asyncEffect();

    return () => {
      controller.abort();
      if (destroy) {
        destroy();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...stateTriggers]);
};

export const useAsyncInterval = (delegate, delay = null, stateTriggers) => {
  const savedCallback = useRef(delegate);

  useEffect(() => {
    savedCallback.current = delegate;
  }, [delegate]);

  useAsyncEffect(
    async (abortSignal) => {
      let id;
      const tick = async () => {
        await savedCallback.current(abortSignal);
        if (delay !== null) {
          id = setTimeout(tick, delay);
        }
      };
      tick();
      return () => id && clearTimeout(id);
    },
    [delay, ...stateTriggers]
  );
};

export const useProductionQuery = (query) => {
  const { request, urls } = useMMAuth();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    let isCurrentQueryActive = true;

    async function fetchData() {
      try {
        const data = await request(`${urls.apiUrl}/reports/production`, {
          method: 'POST',
          body: JSON.stringify(query),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (isCurrentQueryActive) {
          setResult(data);
          setLoading(false);
        }
      } catch (e) {
        if (isCurrentQueryActive) {
          setError(e);
          setLoading(false);
        }
      }
    }
    fetchData();

    return () => {
      isCurrentQueryActive = false;
    };
  }, [query, request, urls.apiUrl]);

  return { data: result, error, loading };
};

export const useWorkOrderOperations = (activitySet) => {
  const [data, setData] = useState();

  const QUERY = gql`
    query WorkOrderOperations($part: String, $lot: String) {
      erpWorkOrderOperations(
        where: { workOrderId: { _eq: $part }, lot: { _eq: $lot } }
      ) {
        resourceId
        workOrderId
        lot
        sub
        split
        sequenceNumber
        startQuantity
      }
    }
  `;

  const response = useQuery(QUERY, {
    variables: {
      part: activitySet.operation.name,
      lot: activitySet.workOrderId,
    },
    skip: !activitySet.workOrderId || !activitySet.operation.name,
  });

  useEffect(() => {
    if (response.data && !response.error) {
      setData(response.data.erpWorkOrderOperations);
    }
  }, [response, activitySet]);

  return { workOrderOperations: data, error: response.error };
};

export const useWorkOrderCandidates = (operation) => {
  const [data, setData] = useState([]);

  const QUERY = gql`
    query WorkOrderCandidates($part: String!, $sequenceNo: Float!) {
      erpWorkOrderOperations(
        where: {
          workOrderId: { _eq: $part }
          sequenceNumber: { _eq: $sequenceNo }
          status: { _eq: "R" }
        }
      ) {
        sequenceNumber
        workOrderId
        lot
        startQuantity
      }
    }
  `;

  const part = operation?.partName;
  const sequenceNo = Number.parseInt(operation?.operationName);

  const response = useQuery(QUERY, {
    variables: {
      part,
      sequenceNo,
    },
    skip: !operation || !part || !operation.operationName,
  });

  useEffect(() => {
    if (response.data && !response.error) {
      setData(response.data.erpWorkOrderOperations);
    }
  }, [response, operation]);

  return { workOrderCandidates: data, error: response.error };
};

export const useAddNewLaborTicket = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const MUTATION = gql`
    mutation erpInsertLaborTicket(
      $transactionDate: timestamptz
      $workOrderId: String!
      $workOrderLot: String!
      $workOrderSub: String!
      $workOrderSplit: String!
      $operationSequence: Float
      $resourceId: String!
      $employeeId: String!
      $type: String!
      $clockIn: timestamptz
      $clockOut: timestamptz!
      $goodQty: Float!
      $badQty: Float!
    ) {
      insertErpLaborTicket(
        object: {
          transactionDate: $transactionDate
          workOrderId: $workOrderId
          lot: $workOrderLot
          sub: $workOrderSub
          split: $workOrderSplit
          sequenceNumber: $operationSequence
          resourceId: $resourceId
          personId: $employeeId
          type: $type
          clockIn: $clockIn
          clockOut: $clockOut
          goodParts: $goodQty
          badParts: $badQty
        }
      ) {
        transactionDate
      }
    }
  `;

  const [insertLaborTicket] = useMutation(MUTATION, {
    onError: (err) => {
      setError(err);
    },
    onCompleted: (data) => {
      setData(data.insertErpLaborTicket);
    },
  });

  const addNewLaborTicket = (ticket) => {
    if (!ticket) return;
    insertLaborTicket({
      variables: {
        transactionDate: ticket?.transactionDate,
        workOrderId: ticket?.workOrderId,
        workOrderLot: ticket?.workOrderLot,
        workOrderSub: ticket?.workOrderSub,
        workOrderSplit: ticket?.workOrderSplit,
        operationSequence: ticket?.operationSequence,
        resourceId: ticket?.resourceId,
        employeeId: ticket?.employeeId,
        type: ticket?.type,
        clockIn: ticket?.clockIn,
        clockOut: ticket?.clockOut,
        goodQty: ticket?.goodQty,
        badQty: ticket?.badQty,
      },
    });
  };

  return { data, error, addNewLaborTicket };
};

export const useEmployees = () => {
  const [data, setData] = useState([]);

  const QUERY = gql`
    query erpPersons {
      erpPersons {
        personId
      }
    }
  `;

  const response = useQuery(QUERY);

  useEffect(() => {
    if (response.data && !response.error) {
      setData(response.data.erpPersons);
    }
  }, [response]);

  return { employees: data, error: response.error };
};

export const useDowntimeQuery = (query) => {
  const { request, urls } = useMMAuth();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useAsyncEffect(
    async (abortSignal) => {
      try {
        const result = await request(`${urls.apiUrl}/reports/downtime`, {
          method: 'POST',
          body: query,
          signal: abortSignal,
        });
        setData(result);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [query]
  );

  return { data, error, loading };
};

export const useCompanySettings = () => {
  const { request, urls } = useMMAuth();
  const [companySettings, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useAsyncEffect(async (abortSignal) => {
    try {
      const result = await request(`${urls.apiUrl}/accounts/current`, {
        method: 'GET',
        signal: abortSignal,
      });
      setData(result);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }, []);

  return { companySettings, error, loading };
};

export const useLaborTickets = (activitySet, activity) => {
  const startAt = DateTime.fromISO(activity.startAt).toUTC().toISO();
  const endAt = activity.endAt
    ? DateTime.fromISO(activity.endAt).toUTC().toISO()
    : undefined;

  const LABOR_TICKETS = gql`
    query LaborTickets(
      $part: String!
      $lot: String!
      $startAt: timestamptz!
      $endAt: timestamptz!
    ) {
      erpLaborTickets(
        where: {
          workOrderId: { _eq: $part }
          lot: { _eq: $lot }
          _or: [
            { clockOut: { _is_null: true } }
            {
              _and: [
                { clockOut: { _gte: $startAt } }
                { clockIn: { _lte: $endAt } }
              ]
            }
          ]
        }
      ) {
        laborTicketId
        transactionDate
        clockIn
        clockOut
        goodParts
        badParts
        personId
        type
        workOrderId
        lot
        sequenceNumber
      }
    }
  `;

  const result = useQuery(LABOR_TICKETS, {
    variables: {
      part: activitySet.operation.name,
      lot: activitySet.workOrderId,
      startAt,
      endAt,
    },
    skip: !activitySet.workOrderId || !activitySet.operation.name,
    pollInterval: 60000, // Poll every 60 seconds
  });

  const overlapThreshold = 0.05;

  const reducer = (accumulator, ticket) => {
    const ticketDuration = new Date(ticket.clockout) - new Date(ticket.clockin);
    const activityDuration =
      new Date(activity.endAt || DateTime.now().toISO()) -
      new Date(activity.startAt);

    let beginningOverlapDuration = 0;
    let endOverlapDuration = 0;

    // Check for beginning overlap
    if (
      new Date(ticket.clockin) <
      new Date(activity.endAt || DateTime.now().toISO())
    ) {
      beginningOverlapDuration =
        new Date(activity.endAt || DateTime.now().toISO()) -
        new Date(ticket.clockin);
    }

    // Check for end overlap
    if (
      new Date(ticket.clockout) > new Date(activity.startAt) &&
      new Date(ticket.clockout) <
        new Date(activity.endAt || DateTime.now().toISO())
    ) {
      endOverlapDuration =
        new Date(ticket.clockout) - new Date(activity.startAt);
    }

    if (
      !(
        (beginningOverlapDuration > 0 &&
          beginningOverlapDuration < overlapThreshold * activityDuration) ||
        (endOverlapDuration > 0 &&
          endOverlapDuration < overlapThreshold * ticketDuration)
      )
    ) {
      accumulator.push(ticket);
    } else {
      console.log(
        `skipped ticket ${ticket.clockin}-${ticket.clockout} from linking to activity ${activity.startAt}-${activity.endAt}`
      );
    }
    return accumulator;
  };

  return {
    laborTickets: result.data
      ? result.data.erpLaborTickets.reduce(reducer, [])
      : [],
    error: result.error,
    refetch: result.refetch,
  };
};
