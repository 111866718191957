import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Select } from '@chakra-ui/react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';
import {
  useUpdateActivitySet,
  getActivitySet,
  getOperation,
} from '../utils/mmutils.js';
import { useWorkOrderCandidates } from '../utils/hooks.js';
import './WorkorderDropdown.css';

// Separate buildConcatenatedName function
const buildConcatenatedName = (activitySet) => {
  if (activitySet.workOrderId) {
    if (activitySet.workOrderId.includes('/')) {
      return `${activitySet.workOrderId}`;
    } else {
      return `${activitySet.operation.name}/${activitySet.workOrderId}`;
    }
  }
  return '';
};

const WorkorderDropdown = ({
  activitySet: initialActivitySet,
  setWorkOrderCandidates,
}) => {
  const { request, urls } = useMMAuth();
  const [operationDetails, setOperationDetails] = useState(null);
  const [selectedActivitySet, setSelectedActivitySet] =
    useState(initialActivitySet);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    async function fetchDetails() {
      const set = await getActivitySet(
        request,
        urls,
        initialActivitySet.activitySetRef
      );
      const operation = await getOperation(request, urls, set.jobId);
      if (isMounted.current) {
        setOperationDetails({ set, operation });
      }
    }
    fetchDetails();
  }, [request, urls, initialActivitySet.activitySetRef]);

  const { workOrderCandidates } = useWorkOrderCandidates(
    operationDetails?.operation
  );

  const { workOrderCandidates: fetchedWorkOrderCandidates } =
    useWorkOrderCandidates(operationDetails?.operation);

  useEffect(() => {
    if (setWorkOrderCandidates && fetchedWorkOrderCandidates) {
      setWorkOrderCandidates(fetchedWorkOrderCandidates);
    }
  }, [fetchedWorkOrderCandidates, setWorkOrderCandidates]);

  const { fetchAndUpdateActivitySet } = useUpdateActivitySet();

  const handleWorkorderChange = useCallback(
    (e) => {
      const selectedLot = e.target.value.split('/')[1];
      setSelectedActivitySet((prevState) => ({
        ...prevState,
        workOrderId: selectedLot,
      }));
      fetchAndUpdateActivitySet(
        initialActivitySet.activitySetRef,
        selectedLot
      ).catch(() => {
        setSelectedActivitySet(initialActivitySet);
        alert('Failed to update workorder. Please try again.');
      });
    },
    [initialActivitySet, fetchAndUpdateActivitySet]
  );

  return (
    <Select
      size="sm"
      value={
        selectedActivitySet ? buildConcatenatedName(selectedActivitySet) : ''
      }
      onChange={handleWorkorderChange}
      disabled={workOrderCandidates?.length === 0}
    >
      <option value="" disabled>
        Select a workorder
      </option>
      {!workOrderCandidates || workOrderCandidates.length === 0 ? (
        <option value="">No released workorders found</option>
      ) : (
        workOrderCandidates.map((workOrder) => (
          <option
            key={workOrder.workOrderId + workOrder.lot}
            value={`${workOrder.workOrderId}/${workOrder.lot}`}
          >
            {workOrder.workOrderId}/{workOrder.lot}
          </option>
        ))
      )}
    </Select>
  );
};

export default WorkorderDropdown;
