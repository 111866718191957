import { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  Heading,
  Select,
  Progress,
  Input,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
} from '@chakra-ui/react';
import {
  useSplitActivitySet,
  useBatchedPartCycles,
} from '../utils/ActivityHooks';
import { DateTime } from 'luxon';
import { gql, useQuery, useSubscription } from '@apollo/client';

function ProductionDivide({
  activitySet,
  workOrderCandidates,
  selectedWorkOrder,
  machineRef,
  isOpen,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [activitySetParts, setActivitySetParts] = useState(null);
  const [goodParts, setGoodParts] = useState(activitySetParts);
  const [newWorkOrder, setNewWorkOrder] = useState(null);

  //this sets the divide based on the existing run end changing
  const existingRunChange = (valueString) => {
    setNewRunEnd(valueString);
  };

  //this will get all of the part cycles for this activity set
  const [activitySetStart, setActivitySetStart] = useState(null);
  const [activitySetEnd, setActivitySetEnd] = useState(null);
  const { data: partCycles } = useBatchedPartCycles(
    activitySetStart,
    activitySetEnd,
    machineRef
  );

  //set the default value of the new run end to the existing run end
  const [newRunEnd, setNewRunEnd] = useState(
    isoToDateTimeLocal(setActivitySetEnd, 'America/New_York')
  );

  //find the beginning and end of the activity set
  useEffect(() => {
    let start = null;
    let end = null;
    activitySet &&
      activitySet.activities.forEach((activity) => {
        if (!start || activity.startAt < start) {
          start = activity.startAt;
        }
        if (!activity.endAt) {
          end = DateTime.now().toISO();
        } else if (!end || activity.endAt > end) {
          end = activity.endAt;
        }
      });
    setActivitySetStart(start);
    setActivitySetEnd(end);
  }, [activitySet]);

  //find the time when the number of good parts is completed
  useEffect(() => {
    if (partCycles && partCycles.length > 0) {
      let aggregatedParts = 0;
      let foundEnd = false;
      partCycles.forEach((part) => {
        aggregatedParts += part.value;
        if (goodParts && aggregatedParts >= goodParts && !foundEnd) {
          setNewRunEnd(isoToDateTimeLocal(part.eventTime, 'America/New_York'));
          foundEnd = true;
          return;
        }
      });
      if (!goodParts) {
        setGoodParts(aggregatedParts);
      }
      setActivitySetParts(aggregatedParts);
    }
  }, [goodParts, partCycles]);

  //this will divide the run
  const { splitActivitySet } = useSplitActivitySet();

  async function divideRun() {
    setIsLoading(true);
    console.log('Submit button pressed');
    try {
      await splitActivitySet(
        activitySet.activitySetRef,
        newRunEnd,
        newWorkOrder
      );
      setIsLoading(false);
      onClose(); // close the modal
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const onNewWorkOrderSelected = (e) => {
    setNewWorkOrder(
      workOrderCandidates.find((workOrder) => workOrder.lot === e.target.value)
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" unmountOnClose={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Divide Production Run</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {activitySetParts !== null ? (
            <Flex>
              <Box w="50%" p={4}>
                <Heading as="h2" size="md" mb={4}>
                  Existing Run
                </Heading>
                <Box mb={4}>
                  <Text>Work Order</Text>
                  <Text>
                    {selectedWorkOrder.workOrderId} / {selectedWorkOrder.lot}
                  </Text>
                </Box>
                <Box mb={4}>
                  <Flex justifyContent="space-between">
                    <Text>Progress</Text>
                    <Text>
                      {goodParts} / {selectedWorkOrder.startQuantity}
                    </Text>
                  </Flex>
                  <Progress
                    value={(goodParts / selectedWorkOrder.startQuantity) * 100}
                  />
                </Box>
                <Box mb={4}>
                  <Text>Good Parts</Text>
                  <NumberInput
                    onChange={(valueString) => setGoodParts(valueString)}
                    defaultValue={activitySetParts}
                    max={activitySetParts}
                    min={0}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
                <Box>
                  <Text>Existing Run End</Text>
                  <Input
                    onChange={(event) => existingRunChange(event.target.value)}
                    type="datetime-local"
                    value={newRunEnd}
                  />
                </Box>
              </Box>
              <Box w="50%" p={4}>
                <Heading as="h2" size="md" mb={4}>
                  New Run
                </Heading>
                <Box mb={4}>
                  <Text>New Work Order</Text>
                  <Select
                    placeholder="Select WorkOrder"
                    onChange={onNewWorkOrderSelected}
                  >
                    {workOrderCandidates &&
                      workOrderCandidates.map((order) => (
                        <option key={order.lot} value={order.lot}>
                          {order.workOrderId} / {order.lot}
                        </option>
                      ))}
                  </Select>
                </Box>
                <Box mb={4}>
                  <Flex justifyContent="space-between">
                    <Text>Progress</Text>
                    <Text>
                      {activitySetParts - goodParts} /{' '}
                      {newWorkOrder && newWorkOrder.startQuantity}
                    </Text>
                  </Flex>
                  <Progress
                    value={
                      newWorkOrder &&
                      ((activitySetParts - goodParts) /
                        newWorkOrder.startQuantity) *
                        100
                    }
                  />
                </Box>
                <Box mb={4}>
                  <Text>Good Parts</Text>
                  <NumberInput isDisabled value={activitySetParts - goodParts}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
                <Box>
                  <Text>New Run Start</Text>
                  <Input type="datetime-local" value={newRunEnd} isDisabled />
                </Box>
              </Box>
            </Flex>
          ) : (
            <Spinner />
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" isLoading={isLoading} onClick={divideRun}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const isoToDateTimeLocal = (isoString, timezone) => {
  if (!isoString) return '';
  const dt = DateTime.fromISO(isoString, { zone: timezone });
  return dt.isValid ? dt.toFormat("yyyy-MM-dd'T'HH:mm") : '';
};

export default ProductionDivide;
