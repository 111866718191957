import React, { useEffect, useState } from 'react';
import StateBar from './StateBar';
import { useQuery, gql } from '@apollo/client';
import { DateTime } from 'luxon';
import styled from 'styled-components';

const DivContainer = styled.div`
  width: 100%;  /* Change to 100% to take up full width */
  height: ${props => `${props.height}px`};  /* Set height from props */
  padding-top: 5px;
  padding-bottom: 5px;
`;
const DivLeft = styled.div`
  float: left;
  padding-right: 0px;  /* Add padding to the right of the text label */
`;

const DivRight = styled.div`
  width: calc(100% - 55px);  /* Deduct the left width and padding */
  float: right;
`;

const colorMapping = {
  'ACTIVE': '#53D73F',
  'READY': '#428bca',
  'UNAVAILABLE': '#CBD5E0',
  'INTERRUPTED': '#428bca',
  'PROGRAM_STOPPED': '#428bca',
  'OPTIONAL_STOP': '#428bca',
  'FEED_HOLD': '#428bca',
  'STOPPED': '#428bca'
};

const ExecutionBar = ({ machineRef, start, end, height, activeCallback }) => {
  const query = gql`
  query queryMachinesListStatusIntervals(
    $machineRef: Int!
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    stateIntervals(
      args: {machineRef: $machineRef, metricKey: "MasterExecution", windowEndAt: $endDate, windowStartAt: $startDate}
      order_by: {startAt: asc}
    ) {
      start: startAt
      end: endAt
      value
    }
  }
`;

const { loading, error, data } = useQuery(query, {
  variables: {
    machineRef,
    startDate: start,
    endDate: end
  },
  skip: !machineRef
});

const [points, setPoints] = useState([]);
const [utilization, setUtilization] = useState();
  useEffect(() => {
      let totalInCycle = 0;
      const events = data ? data.stateIntervals : [];
      let firstActive = null;
      let lastActive = null;
      const pts = events.map((event,i) => {
          const newStart = (i === 0 && DateTime.fromISO(event.start).toUTC() < DateTime.fromISO(start).toUTC()) ? DateTime.fromISO(start).toUTC() : DateTime.fromISO(event.start).toUTC();
          const newEnd = (event.end && i < events.length - 1) ? DateTime.fromISO(event.end).toUTC() : DateTime.fromISO(end).toUTC();
          if (event.value === "ACTIVE") {
            if (!firstActive ) firstActive = DateTime.fromISO(event.start);
            lastActive = event.end ? DateTime.fromISO(event.end) : DateTime.now();
            const cycleTime = newEnd.diff(newStart).as('milliseconds');
            totalInCycle += cycleTime;
          }
          return {
              start: newStart.toMillis(),
              end: newEnd.toMillis(),
              color: colorMapping[event.value]
          }
      }).filter(d => {
          return !(d.start === d.end);
      });
      const totalTime = end ? DateTime.fromISO(end).toUTC().diff(DateTime.fromISO(start).toUTC()).as('milliseconds') : DateTime.utc().diff(DateTime.fromISO(start).toUTC()).as('milliseconds');
      setPoints(pts);
      setUtilization(totalInCycle / totalTime);
      if (typeof activeCallback === "function") {
        activeCallback(firstActive, lastActive)
      }
  }, [data, machineRef, start, end, activeCallback]);

  return (
    <div style={{ width: '100%' }}>
      {loading && 'Loading...'}
      {data && data.stateIntervals && (
      <DivContainer height={height}>  {/* Pass height prop here */}
      <DivLeft>{utilization && Math.round(utilization * 1000) / 10}%</DivLeft>
      <DivRight>
        <StateBar 
          data={points} 
          min={(points && points[0]) ? points[0].start : null} 
          max={(points && points[0]) ? points[points.length - 1].end : null} 
          height={height}  /* The original height prop for StateBar */
          svgHeight={height}
          margin={2}
          preserveAspectRatio={'none'}
        >
        </StateBar>
      </DivRight>
    </DivContainer>
      )}
      {error && (
        <DivContainer>
          {error.message}
        </DivContainer>
      )}
    </div>
  );
};

export default ExecutionBar;