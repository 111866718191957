import PropTypes from 'prop-types';
import React from 'react';

const arrayMax = data => Math.max(...data.map(d => d.x));
const arrayMin = data => Math.min(...data.map(d => d.x));

const dataToPoints = ({ data, width = 1, height = 1, margin = 0, max = arrayMax(data), min = arrayMin(data) }) => {

    const len = max - min;

    const vheight = (height - margin * 2);
    const hfactor = (width - margin * 2) / len;

    const mappedPoints = data.map(d => ({
        x: (d.start - min) * hfactor,
        width: (d.end - d.start) * hfactor,
        height: vheight,
        color: d.color
    })).filter(d => {
        return d.width > 0;
    });
    return mappedPoints;
};

const StateBar = ({ data, width, height, svgWidth, svgHeight, preserveAspectRatio, margin, style, max, min, onMouseMove }) => {
  if (data.length === 0) return null;

  const points = dataToPoints({ data, width, height, margin, max, min });

  const svgOpts = {
    height: height,
    width: "100%",
    style: style,
    viewBox: `0 0 ${width} ${height}`,
    preserveAspectRatio: preserveAspectRatio
  };

  if (svgWidth > 0) svgOpts.width = svgWidth;
  if (svgHeight > 0) svgOpts.height = svgHeight;

  const fillStyle = {
    stroke: style.stroke || 'none',
    strokeWidth: '0',
    fillOpacity: style.fillOpacity || '.1',
    fill: style.fill || 'slategray',
    pointerEvents: 'auto'
  };

  return (
    <svg {...svgOpts}>
      <g transform="scale(1,-1)">
        {points.map((p, i) =>
          <rect
            key={i}
            x={p.x}
            y={-height}
            width={p.width}
            height={height}
            fill={p.color}
            onMouseMove={onMouseMove && onMouseMove.bind(this, p)}
          />
        )}
      </g>
    </svg>
  );
};

StateBar.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  svgWidth: PropTypes.number,
  svgHeight: PropTypes.number,
  preserveAspectRatio: PropTypes.string,
  margin: PropTypes.number,
  style: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  onMouseMove: PropTypes.func,
};

StateBar.defaultProps = {
  data: [],
  width: 480,
  height: 30,
  style: { fill: 'slategray' },
  preserveAspectRatio: 'none',
  margin: 2,
  onMouseMove: () => {},
};

export default StateBar;