// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.machine-section {
  margin-bottom: 0px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.machine {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-bottom: 0px;
}

.machine:last-child {
  border-bottom: none;
}

.machine-name {
  font-size: 1.8em;
  margin-bottom: 4px;
  color: #333;
}

.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line if there's not enough space */
  align-items: center; /* Vertically aligns items in the center */
  gap: 10px; /* Space between items */
  justify-content: flex-start; /* Ensures items are left-justified */
}

.aggregated-data {
  display: flex; /* Makes the <p> tags inside it act as flex items */
  gap: 10px; /* Space between Good Parts and Rejected Parts */
}

.aggregated-data p {
  margin: 0; /* Removes default margin to ensure items sit closer together */
}
`, "",{"version":3,"sources":["webpack://./src/components/List.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,wEAAwE;EACzF,mBAAmB,EAAE,0CAA0C;EAC/D,SAAS,EAAE,wBAAwB;EACnC,2BAA2B,EAAE,qCAAqC;AACpE;;AAEA;EACE,aAAa,EAAE,mDAAmD;EAClE,SAAS,EAAE,gDAAgD;AAC7D;;AAEA;EACE,SAAS,EAAE,+DAA+D;AAC5E","sourcesContent":[".machine-section {\n  margin-bottom: 0px;\n  padding: 8px;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.machine {\n  border-bottom: 1px solid #e0e0e0;\n  padding-bottom: 16px;\n  margin-bottom: 0px;\n}\n\n.machine:last-child {\n  border-bottom: none;\n}\n\n.machine-name {\n  font-size: 1.8em;\n  margin-bottom: 4px;\n  color: #333;\n}\n\n.flex-container {\n  display: flex;\n  flex-wrap: wrap; /* Allows items to wrap onto the next line if there's not enough space */\n  align-items: center; /* Vertically aligns items in the center */\n  gap: 10px; /* Space between items */\n  justify-content: flex-start; /* Ensures items are left-justified */\n}\n\n.aggregated-data {\n  display: flex; /* Makes the <p> tags inside it act as flex items */\n  gap: 10px; /* Space between Good Parts and Rejected Parts */\n}\n\n.aggregated-data p {\n  margin: 0; /* Removes default margin to ensure items sit closer together */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
