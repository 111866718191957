// SplitModal.js
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import './SplitModal.css';

const SplitModal = ({ activity, onClose, onConfirm }) => {

  const validateTime = (time) => {
    const specifiedTime = DateTime.fromISO(time);
    const startTime = DateTime.fromISO(activity.startAt);
    const endTime = activity.endAt ? DateTime.fromISO(activity.endAt) : DateTime.now();

    return specifiedTime >= startTime && specifiedTime <= endTime;
  };

  const localStartAt = DateTime.fromISO(activity.startAt).toLocal().toFormat('yyyy-MM-ddTHH:mm');
  const localEndAt = activity.endAt ? DateTime.fromISO(activity.endAt).toLocal().toFormat('yyyy-MM-ddTHH:mm') : null;

  const [splitTime, setSplitTime] = useState(localStartAt);
  
  return (
      <div className="modal">
        <div className="modal-content">
          <p>Split at the beginning of this activity</p>
          <div>
            <button className="split-activity-button" onClick={() => {
              onConfirm('split', null);
            }}>
              Split Activity 
            </button>
            <p>Split in the middle of the activity by specifying a time</p>
            <div className="time-input-section">
            <input 
              type="datetime-local" 
              value={splitTime}
              min={localStartAt}
              max={localEndAt}
              onChange={(e) => setSplitTime(e.target.value)}
            />
            </div>
            <button className="split-activity-button" onClick={() => {
                if (validateTime(splitTime)) {
                  onConfirm('time', splitTime);
                } else {
                  alert("Please specify a valid time between the start and end of the activity.");
                }
              }}>
                Split Activity At Time
              </button>
              <div className="cancel-button-container">
              <button onClick={onClose} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SplitModal;
