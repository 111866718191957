import React, { memo } from 'react';
import OperationSection from './OperationSection';
import { useCompanySettings, useEmployees } from '../utils/hooks';
import Message from './Message';
import './List.css';
import { chakra, Box, Heading } from '@chakra-ui/react';

const List = ({ items }) => {
  const { companySettings, loading } = useCompanySettings();
  const { employees } = useEmployees();

  return (
    <Box m="1">
      {loading && <Message>Loading...</Message>}
      {items && items.length > 0 && companySettings && employees ? (
        items.map((machine, index) => (
          <Box key={index}>
            <Heading size="lg">{machine.name}</Heading>
            {machine.activitySets.map((activitySet) => (
              <OperationSection
                key={activitySet.activitySetRef}
                activitySet={activitySet}
                machineId={machine.machineId}
                timezone={companySettings.timezone}
                employees={employees}
              />
            ))}
          </Box>
        ))
      ) : (
        <p>No machines found.</p>
      )}
    </Box>
  );
};

export default memo(List);
