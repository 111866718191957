import React, { useState } from 'react';
import ActivityItem from './ActivityItem';
import LaborItems from './LaborItems';
import { useFetchMultipleActivityData } from '../utils/mmutils';
import { Flex, SimpleGrid } from '@chakra-ui/react';

const Activities = ({
  machineId,
  activitySet,
  activities,
  employees,
  timezone,
  workOrderOperations,
}) => {
  const { activitiesData } = useFetchMultipleActivityData(
    activities,
    machineId
  );

  return activities.map((activity, index) => {
    const singleActivityData =
      activitiesData[activity.activityRef]?.data?.data?.aggregate; // getting data from new hook
    return (
      <SimpleGrid key={activity.activityRef} columns={2} spacing={0}>
        <ActivityItem
          key={activity.activityRef}
          activity={activity}
          timezone={timezone}
          activityData={singleActivityData}
        />
        <LaborItems
          key={parseInt(activity.activityRef.toString() + index.toString())}
          activity={activity}
          activitySet={activitySet}
          timezone={timezone}
          activityData={singleActivityData}
          employees={employees}
          workOrderOperation={workOrderOperations?.[0]}
        />
      </SimpleGrid>
    );
  });
};

export default Activities;
