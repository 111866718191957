import React from 'react';
import { gql, useSubscription } from '@apollo/client';
import { DateTime } from 'luxon';
import List from './List';
import Message from './Message';

const Home = () => {
  // Set the default date to a week ago
  const oneWeekAgo = DateTime.local().minus({ weeks: 1 }).startOf('day');

  const machinesQuery = gql`
    subscription GetMachines($startDate: timestamptz!) {
      machines(
        where: { decommissionedAt: { _is_null: true } }
        order_by: { name: asc }
      ) {
        name
        machineRef
        machineId
        partCountMetricKey
        activitySets(
          where: {
            activities: {
              _or: [
                { endAt: { _gte: $startDate } }
                { endAt: { _is_null: true } }
              ]
            }
          }
          order_by: { activities_aggregate: { min: { startAt: desc } } }
        ) {
          activitySetRef
          workOrderId
          operation {
            name
            operationId
          }
          machine {
            name
            machineRef
            machineId
          }
          activities(order_by: { startAt: desc }) {
            startAt
            endAt
            activityRef
            activitySet {
              activitySetRef
              machine {
                name
                machineRef
                machineId
              }
            }
            activityType {
              name
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useSubscription(machinesQuery, {
    variables: { startDate: oneWeekAgo.toISODate() },
  });

  return (
    <div>
      {loading && <Message>Loading...</Message>}
      {data && <List items={data.machines} startDate={oneWeekAgo} />}
      {error && (
        <Message>
          An error occurred:
          <br />"{error.message}"
        </Message>
      )}
    </div>
  );
};

export default Home;
