// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SplitModal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top of other content */
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.time-input-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.split-activity-button,
.split-activity-button,
.cancel-button {
    padding: 10px 15px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-button-container {
  text-align: right;
}

.time-input-section {
    margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SplitModal.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,gCAAgC;EACtE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,gDAAgD;AACjE;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;;;IAGI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iCAAiC;AACrC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* SplitModal.css */\n.modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensure the modal is on top of other content */\n}\n\n.modal-content {\n  background-color: #ffffff;\n  padding: 20px;\n  border-radius: 8px;\n  width: 80%;\n  max-width: 500px;\n}\n\n.time-input-section {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.split-activity-button,\n.split-activity-button,\n.cancel-button {\n    padding: 10px 15px;\n    margin: 5px;\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.cancel-button-container {\n  text-align: right;\n}\n\n.time-input-section {\n    margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
