import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  useDisclosure,
  Box,
  Heading,
  Text,
  Button,
  Divider,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import WorkorderDropdown from './WorkorderDropdown';
import './OperationSection.css';
import {
  useProductionQuery as useProductionData,
  useWorkOrderOperations,
} from '../utils/hooks';
import { DateTime } from 'luxon';
import Activities from './Activities';
import ProductionDivide from './ProductionDivide';

const PaginationControls = ({ totalPages, currentPage, setCurrentPage }) => (
  <Flex alignItems="center" justifyContent="center">
    <Button
      size="sm"
      colorScheme="blue"
      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      disabled={currentPage === 1}
    >
      Previous
    </Button>
    <Text p="2">
      Page {currentPage} of {totalPages}
    </Text>
    <Button
      size="sm"
      colorScheme="blue"
      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
      disabled={currentPage === totalPages}
    >
      Next
    </Button>
  </Flex>
);

const OperationSection = ({ activitySet, machineId, timezone, employees }) => {
  const [isExpandedState, setIsExpandedState] = useState(false);
  const isExpandedRef = useRef(isExpandedState);

  const setIsExpanded = (value) => {
    isExpandedRef.current = value;
    setIsExpandedState(value);
  };

  const [currentPageState, setCurrentPageState] = useState(1);
  const currentPageRef = useRef(currentPageState);

  const setCurrentPage = (page) => {
    currentPageRef.current = page;
    setCurrentPageState(page);
  };

  useEffect(() => {
    if (isExpandedRef.current !== isExpandedState) {
      setIsExpanded(isExpandedRef.current);
    }
  }, []);

  useEffect(() => {
    if (currentPageRef.current !== currentPageState) {
      setCurrentPage(currentPageRef.current);
    }
  }, []);

  const [fetchedData, setFetchedData] = useState(null);

  const sortedActivities = [...activitySet.activities].sort(
    (a, b) => new Date(b.startAt) - new Date(a.startAt)
  );

  const activitiesPerPage = 5;
  const totalPages = Math.ceil(sortedActivities.length / activitiesPerPage);

  const start = (currentPageState - 1) * activitiesPerPage;
  const end = start + activitiesPerPage;

  const startDay = useMemo(() => DateTime.local().minus({ months: 3 }), []);
  const endDay = useMemo(() => DateTime.local(), []);

  const operationQuery = useMemo(
    () => ({
      startDay: startDay.toISODate(),
      endDay: endDay.toISODate(),
      data: [{ metric: 'goodParts' }, { metric: 'rejectedParts' }],
      filter: {
        job: [activitySet.operation.operationId],
        workOrder: [activitySet.workOrderId],
      },
    }),
    [
      startDay,
      endDay,
      activitySet.operation.operationId,
      activitySet.workOrderId,
    ]
  );

  const { workOrderOperations } = useWorkOrderOperations(activitySet);

  const { data: productionData, error: productionDataQueryError } =
    useProductionData(operationQuery);

  useEffect(() => {
    if (productionData && !fetchedData) {
      setFetchedData(productionData);
    }
    if (productionDataQueryError) {
      // Handle error logic here
    }
  }, [productionData, productionDataQueryError, fetchedData]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [workOrderCandidates, setWorkOrderCandidates] = useState([]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <Box m="1">
      <Box m="1" p="2" bg="gray.50">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as="h3" size="md">
            {activitySet.workOrderId
              ? activitySet.workOrderId.includes('/')
                ? ` ${activitySet.workOrderId}`
                : ` ${activitySet.operation.name}/${activitySet.workOrderId}`
              : activitySet.operation.name}
          </Heading>
          <Text>
            {DateTime.fromISO(
              sortedActivities[sortedActivities.length - 1].startAt
            )
              .setZone(timezone)
              .toLocaleString(DateTime.DATETIME_SHORT)}{' '}
            -{' '}
            {sortedActivities[0].endAt
              ? DateTime.fromISO(sortedActivities[0].endAt)
                  .setZone(timezone)
                  .toLocaleString(DateTime.DATETIME_SHORT)
              : 'In Progress'}
          </Text>
          <Button size="xs" colorScheme="blue" onClick={onOpen}>
            Divide
          </Button>
          {isOpen && activitySet && (
            <ProductionDivide
              activitySet={activitySet}
              machineRef={activitySet.machine.machineRef}
              workOrderCandidates={workOrderCandidates}
              selectedWorkOrder={workOrderOperations[0]}
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
            ></ProductionDivide>
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" p="2">
          <WorkorderDropdown
            key={activitySet.workOrderId}
            activitySet={activitySet}
            setWorkOrderCandidates={setWorkOrderCandidates}
          />
          <Spacer />
          <Text width="100%" p="2">
            {new Intl.NumberFormat(navigator.language, {
              maximumFractionDigits: 0,
            }).format(fetchedData?.aggregate?.goodParts || 0)}
            {workOrderOperations &&
              ` / ${new Intl.NumberFormat(navigator.language, {
                maximumFractionDigits: 0,
              }).format(workOrderOperations[0]?.startQuantity)} :`}{' '}
            Good Parts,{' '}
            {new Intl.NumberFormat(navigator.language, {
              maximumFractionDigits: 0,
            }).format(fetchedData?.aggregate?.rejectedParts || 0)}{' '}
            Rejected
          </Text>
          <Spacer />
          <Button
            minWidth="auto"
            size="sm"
            colorScheme="blue"
            onClick={() => setIsExpanded(!isExpandedState)}
          >
            {isExpandedState
              ? 'Collapse'
              : `Expand ${sortedActivities.length} Activities`}
          </Button>
        </Flex>
        {isExpandedState && (
          <Box display="flex" flexDirection="column" gap="3">
            {totalPages > 1 && (
              <Box>
                <PaginationControls
                  totalPages={totalPages}
                  currentPage={currentPageState}
                  setCurrentPage={setCurrentPage}
                />
              </Box>
            )}
            <Divider />
            <Activities
              machineId={machineId}
              activitySet={activitySet}
              activities={sortedActivities.slice(start, end)}
              employees={employees}
              timezone={timezone}
              workOrderOperations={workOrderOperations}
              isExpanded={isExpandedState}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OperationSection;
